/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-size: 1.6rem;
    overflow-y: scroll;
    background-color: #F5F5F5;
}

.height-100vh {
    height: 100vh;
}

.min-height-50vh {
    min-height: 50vh;
}

.logo-image {
    display: block;
    width: 100%;
    height: auto;
    min-width: 300px;
}

.enrolment-card {
    min-width: 350px;
}

.partition-border {
    border-left: 3px solid #dee2e6 !important;
}

@media only screen and (max-width: 768px) {
    .partition-border {
        border-left: 0 solid #dee2e6 !important;
    }
}

.profile-download-link {
    text-decoration: none;
    font-weight: bolder;
    color: #0d6efd;
    cursor: pointer;
}